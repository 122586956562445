import "./App.css";
import BgcForm from "./components/BgcForm";
import { ClassodikaBgc } from "./components/ClassodikaBgc";

function App() {
  return (
    <div className="App">
   <BgcForm/>
    </div>
  );
}

export default App;
